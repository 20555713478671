<template>
	<div class="w-full bg-white">
		<!-- zero :style="{
				backgroundImage:
					'url(' +
					require('@/assets/image/website/Frame_1088.svg') +
					')'
			}" -->
		<div
			class="w-full relative bg-gradient-to-br from-black via-purple-600 to-red-500"
		>
			<!-- <img
				class="paydeck-masthead-image absolute"
				src="@/assets/image/website/Frame_1088.svg"
			/> -->
			<div
				class="container mx-auto link-sheet-20 px-12 pt-36 pb-12 md:p-24 lg:px-36"
			>
				<div class="w-full mx-auto">
					<div
						class="text-5xl md:text-6xl xl:text-6xl 2xl:text-7xl font-extrabold text-white lg:pt-20 paydeck-masthead-header"
					>
						Step-up your Payment game
					</div>
					<div
						class="text-3xl xl:text-4xl font-medium text-left py-6 text-purple-200"
					>
						<span class="">Pay with almost </span>
						<span class="text-white">Zero</span>
						<span class=""> Fees</span>
					</div>
				</div>
				<div class="w-full flex flex-wrap justify-center pt-12">
					<div
						class="w-full md:w-1/3 lg:w-1/3 my-6 md:py-0"
						v-for="(highlight, index) in paydeck_highlights"
						:key="'ph_' + index"
					>
						<div
							class="simple-card bg-white px-4 py-6 lg:p-6 xl:p-8 h-full"
							:class="{
								'md:mx-4 lg:mx-6': index == 1
							}"
						>
							<div
								class="flex flex-col lg:flex-row items-center h-full"
							>
								<img
									class="h-24 w-24 md:h-12 md:w-12 xl:h-20 xl:w-20"
									:src="highlight.image"
								/>
								<div
									class="text-center lg:text-left mt-4 lg:mt-0 ml-0 lg:ml-4"
								>
									<div
										class="text-xl md:text-sm xl:text-md 2xl:text-xl font-medium xl:font-semibold"
										v-html="highlight.caption"
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ------------------ After Landing Page ------------------ -->
		<div class="w-full">
			<!-- ------------- After Simple Card ------------- -->
			<div class="container mx-auto link-sheet-2 text-left">
				<div
					class="grid grid-cols-1 md:grid-cols-2 gap-6 px-12 pb-36 md:px-24 lg:px-36"
				>
					<div
						class="pt-12 xl:pt-24"
						v-for="(stat, si) in paydeck_stats"
						:key="'si+' + si"
					>
						<div
							class="text-7xl xl:text-8xl font-extrabold pb-4 span-blue font-os"
						>
							{{ stat.value }}
						</div>
						<div
							class="text-2xl 2xl:text-3xl pr-6 font-semibold"
							v-html="stat.caption"
						></div>
					</div>
				</div>
			</div>
		</div>
		<!-- ------------------ Your Credit Your Rule ------------------ -->
		<!-- border-b border-dashed border-gray-400 -->
		<div class="w-full flex flex-row items-center">
			<div
				class="container mx-auto link-sheet-10 p-12 md:px-24 lg:px-36 md:pb-48"
			>
				<div class="w-full f-60 lg:f-70 xl:f-100 font-bold">
					Your <br />
					<span
						class="font-extrabold f-100 bg-clip-text text-transparent bg-gradient-to-r from-pink-600 via-purple-600 to-blue-800"
						>Credit Card</span
					>
					<br />
					Your Rule
				</div>
				<div class="bg-gray-100 mt-12" style="height: 3px"></div>
				<div class="grid grid-cols-1 lg:grid-cols-3 gap-5 items-center">
					<div class="col-span-2">
						<div
							class="w-full f-40 font-bold pt-12 pb-6 text-gray-400"
						>
							We will help
							<span class="span-blue font-extrabold">YOU</span>
							pay for
						</div>
						<div class="grid grid-cols-1 md:grid-cols-2">
							<div
								v-for="(service, i) in paydeck_services"
								:key="'service_' + i"
							>
								<div
									class="pay-list py-2"
									:class="{
										'font-bold':
											current_paydeck_service == i
									}"
								>
									{{ service.caption }}
								</div>
							</div>
						</div>
					</div>
					<div>
						<img
							class="w-full animate-flicker"
							:src="
								paydeck_services[current_paydeck_service].image
							"
						/>
					</div>
				</div>
			</div>
		</div>
		<!-- ------------------ Don't Limit Your Card ------------------ -->
		<div class="w-full relative">
			<img
				class="absolute side-image-1 hidden lg:block"
				src="@/assets/image/website/new2.svg"
			/>
			<img
				class="absolute side-image-2 hidden lg:block"
				src="@/assets/image/website/new1.svg"
			/>
			<div
				class="container mx-auto link-sheet-10 text-left p-12 md:px-24 lg:px-36 md:pb-48"
			>
				<div class="grid grid-cols-1 lg:grid-cols-2 gap-24">
					<div>
						<div class="relative">
							<div class="font-bold f-60 w-full">
								<span
									class="f-100 font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-pink-600 via-purple-600 to-indigo-500"
									>Don’t</span
								>
								<br />
								Limit Your Card.
							</div>
							<div class="font-medium f-24 py-8">
								We do believe you can do a lot better than your
								imagination
							</div>
							<img src="@/assets/image/website/limit-card.svg" />
							<img
								class="absolute right-0 -bottom-24"
								src="@/assets/image/website/Separator.svg"
							/>
						</div>
					</div>
					<div>
						<div>
							<div
								id="intro"
								class="limit-card my-4 f-15 mx-auto"
								v-for="(limitCard, index) in limitCards"
								:key="index"
							>
								<table>
									<tr>
										<td>
											<div
												class="font-bold f-24 leading-normal span-blue"
											>
												{{ limitCard.title }}
											</div>
											<div class="pt-4 font-normal">
												{{ limitCard.description }}
											</div>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ------------------ Paydeck Payments Payouts ------------------ -->
		<div class="w-full payment-payout-background">
			<div
				class="container mx-auto link-sheet-10 text-left p-12 md:px-24 lg:px-36 md:py-48"
			>
				<div class="f-100 font-bold text-white">
					<!-- <span class="text-white text-opacity-60"
						>Paydeck Payments</span
					> -->
					Payouts
				</div>
				<div class="f-24 font-medium text-white py-8">
					Paydeck is refreshing with four different payment types from
					August 20 for all customers. Existing payment options will
					be processed faster now. New options have been introduced
					for convenience of the customers. Call support for queries.
					<br /><br />
					We offer Three different type of Payment Payout option with
					the fastest processing :
				</div>
				<div
					class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 md:gap-18 gap-4 pt-16 items-center"
				>
					<div
						class="mb-16"
						v-for="(ptc, pi) in paydeck_transaction_charges"
						:key="'ptc_' + pi"
					>
						<div class="relative wrapper" id="animate-div">
							<!-- ----------- Classic Card ----------- -->
							<div class="card1 px-8 py-5">
								<div
									class="flex flex-row justify-between items-center"
								>
									<div
										class="text-green-500 font-semibold text-xs tracking-wider"
									>
										{{ ptc.caption }}
									</div>
									<div>
										<img class="h-11" :src="ptc.image" />
									</div>
								</div>
								<div
									class="text-white py-3 f-34 font-bold tracking-wider"
								>
									{{ ptc.name }}
								</div>
								<div class="f-16 text-white text-opacity-50">
									{{ ptc.description }}
								</div>
								<div class="text-white font-bold pt-4">
									{{ ptc.settlement_period }}
								</div>
								<div class="text-white font-bold pt-5">
									{{ ptc.settlement_time }}
								</div>
								<div
									class="mx-auto pt-8 pb-10 f-15 font-medium"
								>
									<button
										class="chip-background py-2 px-4 focus:outline-none text-white tracking-wider transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
									>
										{{ ptc.label }}
									</button>
								</div>
							</div>
							<div class="card2 absolute top-0"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ------------------ Introducing Paydeck Loyalty ------------------ -->
		<div class="w-full flex flex-row items-center">
			<div
				class="container mx-auto link-sheet-10 text-left p-12 md:px-24 lg:px-36 md:py-48"
			>
				<div class="f-60 font-bold">
					Introducing Paydeck <br /><span
						class="f-100 font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-pink-600 via-purple-600 to-indigo-800"
						>Loyalty</span
					>
				</div>
				<div class="f-24 font-medium w-full py-8 mb-10">
					300k+ customer have faith and trust on us. This is the high
					time to return back to them. That’s why we have introduced
					Paydeck Loyalty Program.
				</div>
				<!-- ------------------------ Pay Earn Redeem ------------------------ -->
				<!-- ---------- For Desktop ---------- -->

				<div class="hidden md:block relative wrapper" id="animate-div">
					<div class="pay-earn-desktop">
						<div
							class="f-50 font-medium text-left px-5 text-black py-12 px-12 xl:px-24 xl:pt-24"
						>
							Three Easy Steps To Get More From Your Card
						</div>
						<div
							class="flex flex-col md:flex-row md:flex-wrap pay-earn-second px-12 xl:p-24"
						>
							<div
								class="text-7xl md:text-5xl lg:text-6xl xl:text-7xl font-extrabold text-indigo-900 pr-6 xl:pr-12"
							>
								Pay
							</div>
							<div
								class="text-7xl md:text-5xl lg:text-6xl xl:text-7xl font-extrabold text-indigo-700 pr-6 xl:pr-12"
							>
								Earn
							</div>
							<div
								class="text-7xl md:text-5xl lg:text-6xl xl:text-7xl font-extrabold text-pink-600"
							>
								Redeem
							</div>
						</div>
					</div>
					<div class="pay-earn-desktop-1 absolute -top-4"></div>
				</div>
				<!-- ---------- For Mobile View ---------- -->

				<div class="relative wrapper" id="animate-div">
					<div class="pay-earn-mobile block md:hidden p-10">
						<div
							class="f-34 pay-earn-first font-medium text-left md:text-center pb-6"
						>
							Three Easy Steps To Get More From Your Card
						</div>
						<div class="flex flex-col md:flex-row pay-earn-second">
							<div class="f-50 font-bold span-blue-1">Pay</div>
							<div class="f-50 font-bold text-black">Earn</div>
							<div class="f-50 font-bold span-blue-1">Redeem</div>
						</div>
					</div>
				</div>

				<!--  -->
			</div>
		</div>
		<!-- ------------------ Payment Security - In Your Hands ------------------ -->
		<!-- border-b border-t border-dashed border-gray-400 -->
		<div class="w-full">
			<div
				class="container mx-auto link-sheet-10 text-left p-12 md:px-24 lg:px-36 md:pb-48"
			>
				<div class="f-60 font-bold">
					Payment Security In Your<br />
					<span
						class="f-100 font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-pink-600 via-purple-600 to-blue-800"
						>Hands</span
					>
				</div>
				<div class="f-24 font-medium py-8">
					At Paydeck, we always advise our users to focus on following
					key security measures:
				</div>
				<div class="grid grid-cols-1 md:grid-cols-2 py-10">
					<div class="mx-auto hidden xl:block relative">
						<img src="@/assets/image/website/services/8.svg" />
						<img
							class="absolute right-0 -bottom-48"
							src="@/assets/image/website/Separator.svg"
						/>
					</div>
					<!-- ---------- Scroller ---------- -->
					<div class="mx-auto md:col-span-2 xl:col-span-1 xl:pl-24">
						<div class="payment-security p-5">
							<!--  -->
							<div class="f-18 font-normal">
								<table>
									<tr
										v-for="(
											security, i
										) in paydeck_security"
										:key="'security' + i"
									>
										<td class="p-3 md:p-5" valign="top">
											<div
												class="rounded-number text-black"
											>
												{{ i + 1 }}
											</div>
										</td>
										<td
											class="p-3 md:p-5 font-medium span-blue"
										>
											{{ security }}
										</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ------------------ Ready to get started ------------------ -->
		<div class="w-full">
			<div
				class="container mx-auto link-sheet-10 text-left p-12 md:px-24 lg:px-36 md:pb-48"
			>
				<div class="">
					<div class="f-24 font-medium py-8 w-full lg:w-1/2">
						To Know more about Paydeck
						<span class="span-blue">Create an Account</span> and
						start your payment. You can also
						<span class="span-blue">Contact Us</span> to learn more
						about us!
					</div>
					<!-- flex flex-row items-center -->
					<div class="f-60 font-bold">
						<a
							class="f-100 font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-pink-600 via-purple-600 to-blue-800"
							href="https://app.paydeck.in/user/login/new"
							>Let's get started
						</a>
						<!-- <img
							class="h-28 w-28 ml-16"
							src="@/assets/image/arrow.svg"
						/> -->
					</div>
				</div>
				<!-- <div class="py-5">
					<button
						class="focus:outline-none text-white font-normal f-18 get-started-button"
					>
						Get Started
					</button>
					<button
						class="focus:outline-none span-blue f-24 font-medium font-bold mx-5 lg:mx-10 my-10 lg:my-0"
					>
						Contact Us
					</button>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
	data() {
		return {
			limitCards: [
				{
					title: "Surpass Non-acceptance of Cards",
					description:
						"Pay any vendor or individual, even if they dont accept cards.",
					image: require("@/assets/image/website/large-file.svg")
				},
				{
					title: "No more horrors of Cash Handling",
					description:
						"End of the horrors in cash handling, for sending payment or paying invoices.",
					image: require("@/assets/image/website/large-code.svg")
				},
				{
					title: "No collection limit for Payment Requests",
					description:
						"Collect unlimited payment directly in your bank account.",
					image: require("@/assets/image/website/large-file.svg")
				},
				{
					title: "Instant Account Activation",
					description:
						"Signup with your exact PAN details & you are ready to start transacting.",
					image: require("@/assets/image/website/large-code.svg")
				},
				{
					title: "Custom Invoicing",
					description:
						"Create Custom Invoice & start selling your professional services.",
					image: require("@/assets/image/website/large-file.svg")
				},
				{
					title: "Secured Payments",
					description:
						"Secured data with AES-encryption along with 128-bit SSL encryption.",
					image: require("@/assets/image/website/large-code.svg")
				}
			],
			paydeck_highlights: [
				{
					caption: "Connected With All Major Banks in India",
					image: require("@/assets/image/website/mejor-banks-in-india.svg")
				},
				{
					caption: "Maximum Fraud <br/>Prevention",
					image: require("@/assets/image/website/maximum-fraud-prevention.svg")
				},
				{
					caption: "More than 300K happy Customers",
					image: require("@/assets/image/website/happy-customers.svg")
				}
			],
			paydeck_stats: [
				{
					value: "₹10Bn+",
					caption: "Processed Transactions"
				},
				{
					value: "8+",
					caption: "Years Of Flawless Service In Fintech"
				},
				{
					value: "1st",
					caption:
						"India's First Card Based Payment Solution Provider"
				},
				{
					value: "300k+",
					caption: "Strong Family of Paydeck Users"
				}
			],
			paydeck_services: [
				{
					caption: "Society Maintenance Fees",
					image: require("@/assets/image/website/services/17.svg")
				},
				{
					caption: "Home Supplies",
					image: require("@/assets/image/website/services/16.svg")
				},
				{
					caption: "Tuition Fees",
					image: require("@/assets/image/website/services/14.svg")
				},
				{
					caption: "Freelancers",
					image: require("@/assets/image/website/services/2.svg")
				},
				{
					caption: "Taxes",
					image: require("@/assets/image/website/services/10.svg")
				},
				{
					caption: "Office Supplies",
					image: require("@/assets/image/website/services/12.svg")
				},
				{
					caption: "Lease Payment",
					image: require("@/assets/image/website/services/1.svg")
				},
				{
					caption: "Employees",
					image: require("@/assets/image/website/services/7.svg")
				},
				{
					caption: "Contactors",
					image: require("@/assets/image/website/services/4.svg")
				},
				{
					caption: "Legal Services",
					image: require("@/assets/image/website/services/6.svg")
				}
			],
			current_paydeck_service: 0,
			paydeck_security: [
				"Never Share Your Debit Or Credit Card Details on Phone with anyone.",
				"Never Share Your Debit Or Credit Card CVV or OTP received on your mobile phone.",
				"Never reply to anyone claiming to be calling from a Bank or anywhere and providing you any help in doing a transaction",
				"Take a Walk to your nearest bank branch or call your Credit Card Or Debit card Helpline number when you feel suspicious.",
				"Payment Security is in your hands only, thus ensure to play safe!"
			],
			paydeck_transaction_charges: [
				{
					caption: "Featured",
					image: require("@/assets/image/website/logo-1.svg"),
					name: "Classic",
					description:
						"No Sundays or Holidays. Transaction done Today",
					settlement_period: "Credited By Tomorrow",
					settlement_time: "Within 11 AM",
					label: "Most Popular"
				},
				{
					caption: "Featured",
					image: require("@/assets/image/website/logo-2.svg"),
					name: "Instafast",
					description:
						"Make payment now it shall be credited within 1-3 hours",
					settlement_period: "9 AM to 11 PM",
					settlement_time: "365 Days",
					label: "Fastest Solution"
				},
				{
					caption: "Featured",
					image: require("@/assets/image/website/logo-3.svg"),
					name: "Amex",
					description: "Individual & Corporate Customers",
					settlement_period: "Bank Working Day",
					settlement_time: "By 6 PM",
					label: "Especially for Corporates"
				}
			]
		};
	},
	methods: {},
	mounted() {
		var app = this;
		setInterval(() => {
			if (
				app.current_paydeck_service ==
				app.paydeck_services.length - 1
			) {
				app.current_paydeck_service = 0;
			} else {
				app.current_paydeck_service += 1;
			}
		}, 2000);
	}
};
</script>

<style scoped>
.link-sheet-20 {
	width: 100%;
	z-index: 10 !important;
	overflow: hidden !important;
	position: relative;
}
.zero {
	background-size: 0%;
}
.span-color-4 {
	background-image: linear-gradient(#ff9680, #ffccde) !important;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-background-clip: text;
	-moz-text-fill-color: transparent;
}
.span-blue {
	color: #3913b8;
}
.span-blue-1 {
	color: #2249b3;
}
.span-white {
	color: rgba(255, 255, 255, 0.7);
}
.span-white-1 {
	color: rgba(255, 255, 255, 0.87);
}
.get-started-button {
	align-items: center;
	padding: 15px 33px;
	width: 197px;
	height: 54px;
	background: linear-gradient(91.96deg, #3913b8 0%, #ba75ff 100%);
	box-shadow: 0px 20px 40px rgba(113, 62, 215, 0.3);
	border-radius: 30px;
	transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.get-started-button:hover {
	transform: translateY(-5px);
	box-shadow: 0px 20px 40px rgba(113, 62, 215, 0.8);
}
.separate {
	bottom: 100px !important;
}

.payment-payout-background {
	/* background: linear-gradient(
		139.43deg,
		#3913b8 12.46%,
		rgba(57, 19, 184, 0) 101.08%
	); */
	background-image: linear-gradient(
		135.9deg,
		rgba(109, 25, 252, 1) 16.4%,
		rgba(125, 31, 165, 1) 56.1%
	);
	position: relative;
}
.limit-card {
	padding: 30px;
	background: rgba(88, 105, 255, 0.1);
	backdrop-filter: blur(30px);
	border-radius: 20px;
}
.simple-card {
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05),
		0px 20px 40px rgba(92, 103, 153, 0.2);
	border-radius: 30px;
	transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.simple-card:hover {
	transform: translateY(-5px);
	box-shadow: 10px 1px 3px rgba(0, 0, 0, 0.05),
		10px 20px 40px rgba(92, 103, 153, 0.2);
}
.rounded-number {
	background: #dbe1ff;
	height: 30px !important;
	width: 30px;
	border-radius: 50%;
	text-align: center;
	padding: 5px;
}
.chip-background {
	background: rgba(0, 0, 0, 0.2);
	border-radius: 5px;
}
.start-now {
	position: relative;
	background: rgba(255, 255, 255, 0.9);
	box-shadow: 0px 20px 40px rgba(23, 0, 102, 0.3);
	border-radius: 30px;
	transition: 0.3s ease-in-out;
	width: 280px;
	cursor: pointer;
}
.start-now:hover {
	box-shadow: 0px 20px 40px rgba(23, 0, 102, 0.6);
	transform: translateY(-4px);
}

.paydeck-masthead-header {
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
.paydeck-masthead-image {
	margin-top: -30vh;
	height: 100vh;
	width: 120vw;
	max-width: unset;
	overflow: hidden !important;
}
/* Responsive Screen */
/* FOR 4K Displays */
@media only screen and (min-device-width: 2401px) and (max-device-width: 3300px) {
	.paydeck-masthead-image {
		margin-top: -60vh;
		width: 120vw;
		height: unset;
	}
}
/* FOR 2XL Displays */
@media only screen and (min-device-width: 2161px) and (max-device-width: 2400px) {
	.paydeck-masthead-image {
		margin-top: -70vh;
		width: 120vw;
		height: unset;
	}
}
/* FOR 2XL Displays */
@media only screen and (min-device-width: 1441px) and (max-device-width: 2160px) {
	.paydeck-masthead-image {
		margin-top: -42vh;
		width: 100vw;
		height: unset;
	}
}
/* FOR XL Displays */
@media only screen and (min-device-width: 1022px) and (max-device-width: 1440px) {
	.paydeck-masthead-image {
		margin-top: -16vh;
		width: 100vw;
		height: unset;
	}
}
/* FOR TABLET */
@media only screen and (max-device-width: 1024px) and (min-device-width: 768px) {
	.paydeck-masthead-image {
		width: 100%;
		height: auto;
		margin-top: 0vh;
		width: 100vw;
		height: unset;
	}
	.zero {
		background-size: 0%;
	}
}
/* FOR MOBILE */
@media only screen and (max-device-width: 767px) {
	.paydeck-masthead-image {
		opacity: 0;
	}
	.zero {
		background-position: left;
		background-repeat: no-repeat;
		background-size: cover;
		padding-bottom: 20vh;
		margin-bottom: 16vh;
	}
	.link-sheet-20 {
		width: 100%;
		/* padding-top: 20vh;
		padding-bottom: 28vh;
		padding-left: 2vw;
		padding-right: 2vw; */
		z-index: 10 !important;
		overflow: hidden !important;
		position: relative;
	}
}

@keyframes flickerAnimation {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@-o-keyframes flickerAnimation {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@-moz-keyframes flickerAnimation {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@-webkit-keyframes flickerAnimation {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.animate-flicker {
	-webkit-animation: flickerAnimation 2s infinite;
	-moz-animation: flickerAnimation 2s infinite;
	-o-animation: flickerAnimation 2s infinite;
	animation: flickerAnimation 2s infinite;
}
</style>
